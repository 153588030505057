import React from 'react';
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import Camping from "./pages/Camping";
import Vakantiewoning from "./pages/Vakantiewoning";
import Omgeving from "./pages/Omgeving";
import Tarieven from "./pages/Tarieven";
import TarievenNieuw from "./pages/Tarieven";
import Wandelen from "./pages/Wandelen";
import Duurzaamheid from "./pages/Duurzaamheid";
import Contact from "./pages/Contact";
import {Route, Routes} from "react-router-dom"
import Footer from "./components/Footer"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {

    return (
        <>
        <Navbar/>
        <div className='bg-overlay'>
            <div className='container'>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Camping" element={<Camping />} />
                    <Route path="/Vakantiewoning" element={<Vakantiewoning />} />
                    <Route path="/Omgeving" element={<Omgeving />} />
                    <Route path="/Tarieven" element={<Tarieven />} />
                    <Route path="/TarievenNieuw" element={<Tarieven />} />
                    <Route path="/Wandelen" element={<Wandelen />} />
                    <Route path="/Duurzaamheid" element={<Duurzaamheid />} />
                    <Route path="/Contact" element={<Contact />} />
                </Routes>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default App;