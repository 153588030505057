
import FadeIn from "react-fade-in/lib/FadeIn"

export default function Contact() {
    return (
        <FadeIn transitionDuration={1000}>
        <h1>Contact</h1>
        <div className='pageContentDiv'>
            <div className='zijText'>
                <p>
                    Voor meer informatie of het boeken van een kampeerplek of vakantiewoning kunt u telefonisch of per 
                    e-mail contact met ons opnemen. 
                </p>
                <p>
                    Jan en Diane ten Tije<br/> 
                    Oude Rijssenseweg 19a<br/>
                    7475 SM Markelo
                </p>
                <p>
                    Telefoonnummer: 0547-334536<br/> 
                    E-mail: dekeite@groenecamping.nl 
                </p>
                <p>
                    Inschrijfnr. Kamer van Koophandel: 64753441 
                </p>
            </div>
            <div class="zijfotosDiv">
                <img className="zijfoto" src={require("../images/huis.JPG")} alt="huisPic"/>
            </div>
            <div id="mapsContainer">
                <iframe id="googleMaps"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41404.02151832506!2d6.499253859964045!3d52.252242289195436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7f4316be40fa3%3A0xd1297cb05fef4c4!2sGreen%20Camping%20Keite!5e0!3m2!1sen!2snl!4v1662901394771!5m2!1sen!2snl"
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        />
            </div>
        </div>
    </FadeIn>
    )
}