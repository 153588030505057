import React from 'react';
import ReactDOM from 'react-dom';
import "./styles.css"
import {BrowserRouter} from "react-router-dom"

import App from './App';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)