import FadeIn from 'react-fade-in';

export default function Wandelen() {
    return (
        <FadeIn transitionDuration={1000}>
            <h1>Wandelen en fietsen</h1>
            <div className='pageContentDiv'>
                <div className='zijText'>
                    <p>
                        De wandel- en fietsmogelijkheden zijn eindeloos. Het landschap is glooiend en afwisselend. 
                        De wandel- en fietsknooppuntennetwerken bieden u de mogelijkheid lange en korte aantrekkelijke 
                        tochten te wandelen en te fietsen.  
                    </p>
                    <h4>Fiets- en wandelroutes</h4>
                    <p>
                        Bij de Tourist Info Hof van Twente in Markelo (tel. 0547-361555) zijn fiets- en wandelroutes verkrijgbaar. 
                    </p>
                    <p>
                        Klik <a target="_blank" rel="noreferrer" href="https://www.routeyou.com/nl-nl/location/walk/47307135/wandelen-in-markelo-overzicht-van-alle-wandelroutes">
                        hier</a> voor wandelroutes rondom Markelo en omgeving. Voor fietsroutes, waaronder de kastelenroute 
                        Markelo-Diepenheim, klik <a target="_blank" rel="noreferrer" href="https://www.fietsknoop.nl/fietsroute/19206910/kastelenroute-markelo-diepenheim-51-7-km">hier</a>.  
                    </p>
                    <h4>Fietsvierdaagse</h4>
                    <p>
                        U kunt er ook voor kiezen mee te doen aan een fietsvierdaagse. In Markelo wordt deze de derde 
                        week van juni georganiseerd. In Laren wordt ’s zomers en in Goor in de herfst een fietsvierdaagse 
                        georganiseerd. 
                    </p>
                    <h4>Ruiterpaden</h4>
                    <p>
                        In de directe omgeving zijn aantrekkelijke ruiterpaden. Een lust voor de paardenliefhebber. 
                        U kunt uw paard in een van onze weilanden laten lopen.
                    </p>
                </div>
                <div className="zijfotosDiv">
                    <img className="zijfoto" src={require("../images/IMG_8276.JPG")} alt="bloemPic"/>
                </div> 
            </div>
        </FadeIn>
    )
}