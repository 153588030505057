const ImageHoverZoom = ({ imagePath, text }) => {
    return (
        <div className="img-wrapper">
            <img
                src={imagePath}
                alt=""
                className="hover-zoom"
            />
            <h2 id="imageText">{text}</h2>
        </div>
    );
    };
    export default ImageHoverZoom;