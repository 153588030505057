import FadeIn from 'react-fade-in';
import React from 'react';
import { Zoom } from "react-slideshow-image";
import Magnifier from "react-magnifier";
import 'react-slideshow-image/dist/styles.css'

const images = [require("../images/campingfotos/kampplek.jpg"), require("../images/campingfotos/kampplek2.JPG"), 
                require("../images/campingfotos/kampplek3.jpg"), require("../images/campingfotos/kampplek4.jpg"), 
                require("../images/campingfotos/kampplek5.jpeg"), require("../images/campingfotos/kampplek6.JPG"),
                require("../images/campingfotos/kampplek7.jpg")];

const zoomOutProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true
  };
  
  const Slideshow = () => {
    return (
      <div className="slide-container">
        <Zoom {...zoomOutProperties}>
          {images.map((each, index) => (
            <img key={index} className="slideImage" src={each} alt="slide"/>
          ))}
        </Zoom>
      </div>
    );
  };

export default function Camping() {
    return (
        <FadeIn transitionDuration={1000}>
          <h1>Camping</h1>
          <div className='pageContentDiv'>
            <div className='zijText'>
              <p>
                    De Keite is uniek! U staat niet op een veld met tenten en caravans, maar u heeft een eigen stekje met 
                    vooral vogels als buren, die verscholen in de struiken u laten genieten van hun gezang. 
                </p>
                <p>
                    Het terrein rondom het voormalige boerenerf is ruim ingedeeld met veel groen. De gevarieerde inheemse 
                    (ecologisch onderhouden) terreinbeplanting biedt aan veel vogels, kleine zoogdieren en insecten 
                    aantrekkelijke nest- en schuilgelegenheid. 
                </p>
                <p>
                    Door de aanwezig beplanting kunt u optimaal genieten van uw privacy.
                </p>
                <p>
                    De plaatsen zijn voorzien van elektra-aansluitingen 
                    (4 ampère groene stroom). In de buurt van de plaatsen is een watertappunt.
                </p>
                <p>
                    In verband met behoud van gras en het verminderen van plastic afval is het niet toegestaan 
                    om plastic onder de voortent of tent te leggen. 
                </p>
                <p>
                    Vanaf 2025 zijn er op ons terrein een aantal plekken waar geen auto meer mag staan. Uiteraard mag u de auto nog wel meenemen 
                    op de dag van aankomst en de dag van vertrek. Tijdens uw verblijf staat de auto op het gras vooraan bij het sanitair gebouw.
                </p>
                <p>
                    In het sanitairgebouw zijn douches en wascabines met warm en koud water, toiletten en een heetwatertappunt 
                    met afwasplaats. Tevens is er een stortplaats voor chemisch toilet.
                </p>
                <p>
                    In de receptieruimte op de deel van de boerderij kunt u gebruik maken van WiFi (ook buiten, rond de receptieruimte is WiFi beschikbaar). 
                </p>
                <p>
                    Onze camping wordt ook genoemd op de website RECRON.nl in de blog over <a target="_blank" href='https://www.recron.nl/campings/kleine-campings-nederland'>
                    kleine campings</a>. Als lid van HISWA-RECRON bent u 
                    verzekerd van goede en betrouwbare voorwaarden.
                </p>
              </div>
            <div class="zijfotosDiv">
            <div className='sContainerContainer'>
                <div className='sliderContainer'>
                    <Slideshow/>
                </div>
              </div>
            </div>
            <div id='plattegrond'>
              <Magnifier src={require("../images/plattegrondNieuw.png")}/>
            </div>
        </div>
        </FadeIn>
    )
}