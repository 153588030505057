import FadeIn from "react-fade-in/lib/FadeIn"

export default function Duurzaamheid() {
    return (
        <FadeIn transitionDuration={1000}>
            <h1>Duurzaamheid</h1>
            <div className='pageContentDiv'>
                <div className='zijText'>
                    <p>
                        Op de Keite proberen we duurzaam en verantwoordelijk met onze omgeving om te gaan. 
                        Natuur en de mens zijn belangrijk. Op en rond het terrein is veel plaats voor inheemse planten en dieren. 
                    </p>
                    <p>
                        De Keite is opgenomen in het cultuurhistorische landschap. Doorkijkjes en wandelpaden op en rond het 
                        terrein maken dit landschap goed beleefbaar. Rond het terrein liggen kleine weilandjes, bosjes en akkers. 
                        De Keite maakt deel uit van het landschap en andersom treedt het landschap het terrein binnen. Dit geeft 
                        een aangenaam gevoel. 
                    </p>
                    <p>
                        Wij gebruiken ecologisch verantwoorde schoonmaakmiddelen en zijn zuinig met energie. Er is geen overdadige 
                        verlichting. De helft van onze electriciteit produceren wij zelf door middel van zonnepanelen. 
                    </p>
    
                </div>
                <div className="zijfotosDiv">
                    <img className="zijfoto" src={require("../images/campingfotos/kampplek6.JPG")} alt="schaapPic"/>
                    
                </div> 
            </div>
        </FadeIn>
    )
}