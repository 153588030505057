import FadeIn from 'react-fade-in';

export default function Omgeving() {
    return (
        <FadeIn transitionDuration={1000}>
            <h1>Omgeving</h1>
            <div className='pageContentDiv'>
                <div className='zijText'>
                    <h4>Natuur en landschap</h4>
                    <p>
                        De omgeving van De Keite is bosrijk en afwisselend door weilanden, akkers, heuvels, heide, houtwallen, 
                        boerderijen en landgoederen. Ideaal om fietstochten en wandelingen te maken. Op circa 1 kilometer 
                        afstand ligt natuurgebied De Borkeld met bos en heide. Hier zijn gerestaureerde grafheuvels uit de prehistorie 
                        te vinden. Ook kunt u daar de schaapskudde tegenkomen die daar rondtrekt. 
                    </p>
                    <h4>Dorp</h4>
                    <p>
                        Voor boodschappen in Markelo, circa 2 kilometer vanaf de Keite, kunt u terecht bij een warme bakker, 
                        kaaswinkel, supermarkten en andere winkels. Ook kunt u daar fietsen huren. 
                    </p>
                    <h4>Bezienswaardigheden en activiteiten</h4>
                    <p>
                        In en rond Markelo is er een breed scala aan mogelijkheden voor dagjes uit of het doen van activiteiten. 
                        Het moderne zwembad en tennisbanen liggen op ongeveer 10 minuten fietsen van De Keite.
                        <div id='molenLinksDiv'>
                            <a target="_blank" rel="noreferrer" href="http://www.depelmolen.nl/">
                                <button className='molenButtons'>
                                Molen Rijssen
                                </button>
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.molenvanbuursink.nl/">
                                <button className='molenButtons'>
                                Molen Markelo
                                </button>
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.entersezomp.nl/">
                                <button className='molenButtons'>
                                Scheepswerf Enter
                                </button>
                            </a>

                        </div>

                    </p>
    
                </div>
                <div className="zijfotosDiv">
                    <img className="zijfoto" src={require("../images/schapen.jpg")} alt="schaapPic"/>
                    
                </div> 
            </div>
        </FadeIn>
    )
}