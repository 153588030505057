import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
  
function formatOurData(columnName, semOneMarks) {
  return { columnName, semOneMarks};
}

function formatOurData3(columnName, semOneMarks, semTwoMarks, semThreeMarks) {
    return { columnName, semOneMarks, semTwoMarks, semThreeMarks};
}

function formatOurData4(columnName, semOneMarks, semTwoMarks) {
  return { columnName, semOneMarks, semTwoMarks};
}

const toeristenPrijzen2023 = [
  formatOurData("Persoon",                          "€ 6,50"),
  formatOurData("Kampeermiddel",                    "€ 7,00"),
  formatOurData("Aansluiting elektra (4 Ampère)",   "€ 3,00"),
  formatOurData("Auto",                             "€ 2,00"),
  formatOurData("Milieubijdrage p.p.",              "€ 0,90"),
  formatOurData("Toeristenbelasting p.p.",          "€ 1,10"),
  formatOurData("Douchen",                          "€ 0,50"),
  formatOurData("Extra bijzettent",                 "€ 5,00"),
  formatOurData("Onbewoond",                        "€ 7,00"),
  formatOurData("Hond",                             "€ 2,00"),
  formatOurData("Paard",                            "€ 6,00"),
];

const toeristenPrijzen2024 = [
  formatOurData("Persoon",                          "€ 7,00"),
  formatOurData("Kampeermiddel",                    "€ 7,50"),
  formatOurData("Camper",                           "€ 8,00"),
  formatOurData("Aansluiting elektra (4 Ampère)",   "€ 3,00"),
  formatOurData("Auto",                             "€ 2,50"),
  formatOurData("Milieubijdrage p.p.",              "€ 0,90"),
  formatOurData("Toeristenbelasting p.p.",          "€ 1,10"),
  formatOurData("Douchen",                          "€ 0,50"),
  formatOurData("Extra bijzettent",                 "€ 5,00"),
  formatOurData("Onbewoond",                        "€ 10,00"),
  formatOurData("Hond",                             "€ 2,50"),
  formatOurData("Paard",                            "€ 7,00"),
  formatOurData("Trekkersplek 1 persoon (1 nacht 16:00 - 10:00)",  "€ 14,00"),
  formatOurData("Trekkersplek 2 personen (1 nacht 16:00 - 10:00)",  "€ 19,00"),
];

const toeristenPrijzen2025 = [
  formatOurData3("1 persoon",                     "€ 18,00", "€ 20,00",  "€ 18,00"),
  formatOurData3("2 personen",                    "€ 23,00", "€ 27,00",  "€ 23,00"),
];

const extraPrijzen2025 = [
  formatOurData("Extra persoon",                    "€ 7,00"),
  formatOurData("Bijzettent",                    "€ 5,00"),
  formatOurData("Toeristenbelasting p.p.",          "€ 1,10"),
  formatOurData("Douchen",                          "€ 0,50"),
  formatOurData("Hond (max 2)",                     "€ 2,00"),
  formatOurData("Passant camper water",             "€ 5,00"),
];

const oktoberPrijzen2024 = [
  formatOurData("Midweek (maandag tot vrijdag) 1 persoon",     "€ 88,00"),
  formatOurData("Midweek (maandag tot vrijdag) 2 personen",   "€ 124,00"),
  formatOurData("Weekarrangement van 7 nachten 1 persoon",    "€ 154,00"),
  formatOurData("Weekarrangement van 7 nachten 2 personen",  "€ 198,00"),
];

const hemelvaartPrijzen = [
  formatOurData("Persoon",                          "€ 7,50"),
  formatOurData("Kampeermiddel",                    "€ 8,00"),
  formatOurData("Aansluiting elektra (4 Ampère)",   "€ 3,00"),
  formatOurData("Auto",                             "€ 2,00"),
  formatOurData("Milieubijdrage p.p.",              "€ 0,90"),
  formatOurData("Toeristenbelasting p.p.",          "€ 1,10"),
  formatOurData("Extra bijzettent",                 "€ 5,00"),
  formatOurData("Onbewoond",                        "€ 10,00"),
];

const onbewoondPrijzen = [
    formatOurData("Caravan/camper",                 "€ 6,00"),
    formatOurData("Caravan/camper zonder elektra",  "€ 4,50"),
]

const huisjesPrijzen = [
    formatOurData3("06/01/2023 - 03/03/2023",           "€ 300,-", "€ 275,-", "€ 250,-"),
    formatOurData3("03/03/2023 - 13/05/2023",           "€ 475,-", "€ 300,-", "€ 300,-"),
    formatOurData3("13/05/2023 - 30/09/2023",           "€ 525,-", "€ 325,-", "€ 325,-"),
    formatOurData3("30/09/2023 - 30/12/2023",          "€ 475,-", "€ 300,-", "€ 300,-"),
  ];

  const huisjesPrijzen2024 = [
    formatOurData4("01/01/2024 - 01/04/2024",           "€ 475,-", "€ 350,-"),
    formatOurData4("01/04/2024 - 01/11/2024",           "€ 560,-", "€ 400,-"),
    formatOurData4("01/11/2024 - 01/01/2025",           "€ 475,-", "€ 350,-"),
  ];

  

  
export default function PriceTable(props) {

    const returnPrijzenString = () => {
        switch (props.name) {
            case "onbewoondTable":
              return onbewoondPrijzen
            case "huisjesTable":
              return huisjesPrijzen
            case "huisjesTable2024":
              return huisjesPrijzen2024
            case "toeristenTable2023":
              return toeristenPrijzen2023
            case "toeristenTable2024":
              return toeristenPrijzen2024
            case "toeristenTable2025":
              return toeristenPrijzen2025
            case "hemelvaartTable":
              return hemelvaartPrijzen
            case "oktoberTable":
              return oktoberPrijzen2024
            case "extraTable2025":
              return extraPrijzen2025
            default:
              return <h4>Error</h4>
            }
    }

    const firstColumName = () => {
      switch (props.name) {
        case "huisjesTable":
          return "Periode"
        case "huisjesTable2024":
          return "Periode"
        default:
          return ""
        }
    }

    const secondColumnName = () => {
      switch (props.name) {
        case "huisjesTable":
          return "Week"
        case "huisjesTable2024":
          return "Week"
        case "toeristenTable2025":
          return "01/04 - 25/05"
        default:
          return "Prijs"
        }
    }

    const extraColumns = () => {
      switch (props.name) {
        case "huisjesTable":
          return (
            <>
              <TableCell align="right">
                Midweek
              </TableCell>
              <TableCell align="right">
                Weekeinde
              </TableCell>
            </>
          )
        case "huisjesTable2024":
          return (
            <>
              <TableCell align="right">
                Midweek/Weekeinde
              </TableCell>
            </>
          )
        case "toeristenTable2025":
          return (
            <>
              <TableCell align="right">
                26/05 - 31/08
              </TableCell>
              <TableCell align="right">
                01/09 - 30/09
              </TableCell>
            </>
          )
        default:
          return ""
        }
    }

    const extraCells = (row) => {
      switch (props.name) {
        case "huisjesTable":
          return (
            <>
              <TableCell className="prijsCell" align="right">
                  {row.semTwoMarks}
              </TableCell>
              <TableCell className="prijsCell" align="right">
                  {row.semThreeMarks}
              </TableCell>
            </>
          )
        case "huisjesTable2024":
          return (
            <>
              <TableCell className="prijsCell" align="right">
                  {row.semTwoMarks}
              </TableCell>
            </>
          )
        case "toeristenTable2025":
          return (
            <>
              <TableCell className="prijsCell" align="right">
                  {row.semTwoMarks}
              </TableCell>
              <TableCell className="prijsCell" align="right">
                  {row.semThreeMarks}
              </TableCell>
            </>
          )
        default:
          return ""
        }
    }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className="tarievenTablesOpmaak">
          <TableHead>
            <TableRow>
              <TableCell>{firstColumName()}</TableCell>
              <TableCell align="right">{secondColumnName()}</TableCell>
              {extraColumns()}
            </TableRow>
          </TableHead>
          <TableBody>
            {returnPrijzenString().map((row) => (
              <TableRow key={row.columnName}>
                <TableCell component="th" scope="row">
                  {row.columnName}
                </TableCell>
                <TableCell className="prijsCell" align="right">
                    {row.semOneMarks}
                </TableCell>
                {extraCells(row)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}