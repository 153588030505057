import {Link, useMatch, useResolvedPath} from "react-router-dom";
import logo from '../images/logoGroen.png';
import React from 'react';

export default function Navbar(){

    return (
        <div id="fixedNav">
            <nav className="navbar">
            <div className="logoDiv">
                <Link to="/"><img className = "navbarLogo" src={logo} alt="logo" /></Link>
            </div>
            <div className="nav-links">
                <input type="checkbox" id="checkbox_toggle" />
                <label for="checkbox_toggle" class="hamburger">&#9776;</label>
                <div className="menu">
                    <CustomLink to="/Camping">Camping</CustomLink>
                    <CustomLink to="/vakantiewoning">Vakantiewoning</CustomLink>
                    <CustomLink to="/Omgeving">Omgeving</CustomLink>
                    <CustomLink to="/Tarieven">Tarieven</CustomLink>
                    <CustomLink to="/Wandelen">Wandelen en fietsen</CustomLink>
                    <CustomLink to="/Duurzaamheid">Duurzaamheid</CustomLink>
                    <CustomLink to="/Contact">Contact</CustomLink>
                </div>
            </div>
        </nav>
    </div>
    )
}

function chk_control() {
    document.getElementById('checkbox_toggle').checked =false;
    }

function CustomLink({to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    return(
        <Link onClick={chk_control} to={to} {...props}>
            <li className={isActive ? "active" : ""}>
                {children}
            </li>
        </Link>
    )
}