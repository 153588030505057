import React from 'react';
import FadeIn from 'react-fade-in';
import kampPic from '../images/campingfotos/kampplek2.JPG';
import vakantiePic from '../images/huisje1/IMG_8790.JPG';
import ImageHoverZoom from '../components/ImageHoverZoom';
import {Link} from "react-router-dom";


const ImageButton = (props) => {
    return (
        <>
            <button className='imageButton' >
                <Link to={props.page} {...props}>
                    <ImageHoverZoom imagePath={props.pic} text={props.text}/>
                </Link>
            </button>
        </>
    );
}

export default function Home() {
    return (
        <FadeIn transitionDuration={1000}>
            <div className="welcomeText">
                <h1>Welkom bij de Keite</h1>
                <p>
                    De Keite is groen genieten in Markelo, gemeente Hof van Twente.<br/>
                    Het erf en de omliggende gronden zijn met liefde voor natuur en streek ingericht met inheemse beplantingen.<br/>
                    Op ons terrein staat u midden in het groen!<br/>
                    Tot ziens op ons erf!
                </p>
                <div>
                    <ImageButton page={"/Camping"} pic={kampPic} text="Kamperen"/>
                    <ImageButton page={"/Vakantiewoning"} pic={vakantiePic} text="Vakantiewoning"/>
                </div>
            </div>
        </FadeIn>
    )
}