import FadeIn from 'react-fade-in';

const zijFotos = [require("../images/huisje1/IMG_8790.JPG"), require("../images/huisje1/IMG_8305.JPG"), 
                require("../images/huisje1/IMG_9102.JPG"), require("../images/huisje1/IMG_1104.JPG")];

const onderFotos = [require("../images/huisje1/IMG_1101.JPG"), 
                require("../images/huisje2/IMG_8782.JPG"), require("../images/huisje2/IMG_8291.JPG"), 
                require("../images/huisje2/IMG_9181.JPG"), require("../images/huisje2/IMG_9182.JPG"),
                require("../images/huisje2/IMG_9183.JPG")];

const HuisjesFotos = (props) => {
    return (
        <>
        {props.images.map(item => {
          return (
            <img className={props.images === onderFotos ? "onderfoto" : "zijfoto"} src={item} alt="huisjespic"/>
          );
        })}
        </>
    );
    };


export default function Vakantiewoning() {
    return (
        <FadeIn transitionDuration={1000}>
            <h1>Vakantiewoning</h1>
            <div className='pageContentDiv'>
                <div className="introText">
                    <p>
                        Rustiek, apart van het kampeergedeelte, staan twee vakantiewoningen. De woningen 
                        liggen in en naast een kleine boomgaard (ongeveer 800 m2), beschut door gevarieerde 
                        houtsingels bestaande uit o.a. meidoorn, lijsterbes, hazelaar, veldesdoorn, haagbeuk, krent, 
                        boerenjasmijn, wilde liguster en kornoelje. Een prachtige zeer ruime plek voor de rustige 
                        vakantieganger die van natuur en privacy houdt. 
                    </p>
                </div>
                <div className='zijText'>
                    <h4>Beschrijving</h4>
                    <p>
                        De vakantiewoningen zijn ruim en comfortabel ingericht. De goed geïsoleerde buitengevels zijn 
                        bekleed met inlands lariks van landgoed Twickel. De daken zijn bedekt met zonnepanelen.
                        Door de aanwezige van beplantingen kunt optimaal genieten van uw privacy.  
                    </p>
                    <h4>Indeling</h4>
                    <p>
                        Hal, toilet, woon/eetkamer met open keuken. Één slaapkamer met tweepersoonsbed, badkamer met 
                        wastafel, royale douche en tweede toilet en een inpandige berging. Alle vertrekken zijn gelijkvloers 
                        en voorzien van plavuizen. 
                    </p>
                    <h4>Inrichting</h4>
                    <p>
                        De inrichting is compleet met eethoek, zithoek, servies, kookgerei, bestek, koffiezetapparaat, 
                        satelliet-TV en -radio, dekbedovertrekken, bedlinnen, verstelbare tuinstoelen, etc. Keukenlinnen en badlinnen 
                        verzoeken wij u zelf mee te nemen. 
                    </p>
                    <h4>Parkeren auto</h4>
                    <p>
                        De auto kunt u parkeren bij de vakantiewoning.
                    </p>
                    <h4>Wasmachine</h4>
                    <p>
                        U kunt gebruik maken van de wasmachine in de vakantiewoning. Waspoeder moet u zelf meenemen.
                    </p>
                    <h4>Aantal personen</h4>
                    <p>
                        De vakantiewoning wordt verhuurd aan maximaal 2 personen.
                    </p>
                    <h4>Minder validen</h4>
                    <p>
                        Een van de twee vakantiewoningen heeft een vergrote slaapkamer, waardoor deze geschikt 
                        is voor minder validen.
                    </p>
                    <h4>Internet</h4>
                    <p>
                        In beide vakantiewoningen is het mogelijk gratis gebruik te maken van WiFi. 
                    </p>
                    <h4>Aankomst en vertrektijden</h4>
                    <p>
                        Aankomsttijd is vanaf 15:00 uur. Vertrektijd is tot 10:00 uur. 
                    </p>
                    <b>Roken en huisdieren zijn niet toegestaan</b>
                </div>
                <div className="zijfotosDiv">
                    <HuisjesFotos images={zijFotos}/>
                </div> 
                <div className="onderfotosDiv">
                    <HuisjesFotos images={onderFotos}/>
                </div> 
            </div>
        </FadeIn>
    )
}